/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Header, Sidebar } from '../../components/common'
import { Box } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import WhatsappQRModal from '../../components/common/WhatsappQRModal'
import { useSelector } from 'react-redux'
function Layout() {
	const navigate = useNavigate()
	const user = useSelector((state) => state.user)
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const handleDrawerToggle = () => {
		setIsDrawerOpen((previous) => !previous)
	}
	useEffect(() => {
		if (user.token === null) {
			navigate('/login')
		}
	}, [user.token])
	const matches = useMediaQuery('(min-width: 600px)')
	return (
		<div>
			<header>
				<Header handleDrawerToggle={handleDrawerToggle} open={isDrawerOpen} />
			</header>
			<Box sx={{ display: 'flex' }}>
				{isDrawerOpen && <Sidebar isDrawerOpen={isDrawerOpen} onClose={handleDrawerToggle} />}
				<Box
					sx={{
						width: '100%',
						marginTop: 10,
						marginLeft: matches ? 2 : 0,
					}}>
					{!user.whatsapp && <WhatsappQRModal />}
					<Outlet />
				</Box>
			</Box>
		</div>
	)
}

export default Layout
