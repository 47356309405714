import { Box, Typography, Chip } from '@mui/material'
import CallMadeIcon from '@mui/icons-material/CallMade'
import CallReceivedIcon from '@mui/icons-material/CallReceived'

export default function partnerDetailAcivityCallLog(props) {
	const callDetails = props.activity.call_details
	function timeToMinutes(time) {
		let parts = time.split(':')
		let hours = parseInt(parts[0], 10)
		let minutes = parseInt(parts[1], 10)
		let seconds = parseInt(parts[2], 10)
		function truncateToTwoDecimals(number) {
			return Math.trunc(number * 100) / 100
		}

		// Convert hours and seconds to minutes
		return `${truncateToTwoDecimals(hours * 60 + minutes + seconds / 60)}`
	}
	return (
		<Box sx={{ padding: 2, margin: 2, borderRadius: 2, border: '1px black dashed', width: '100%' }}>
			<Box sx={{ marginLeft: 1, marginBottom: 1 }}>
				<Box sx={{ display: 'flex' }}>
					<Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
						{props.activity.call_details.call_direction === 'outgoing' ? (
							<CallMadeIcon sx={{ color: 'orange', marginRight: 0.5, width: '25px' }} />
						) : (
							<CallReceivedIcon sx={{ color: 'green', marginRight: 0.5, width: '25px' }} />
						)}
						<Typography>{props.activity.call_details.call_direction.toUpperCase()}</Typography>
					</Box>
					<Typography>
						<Chip
							label={`${callDetails.call_status}`}
							sx={{
								color: 'white',
								backgroundColor:
									callDetails.call_status === 'Connected'
										? 'green'
										: callDetails.call_status === 'Not Connected'
										? 'orange'
										: 'red',
							}}
						/>
					</Typography>
				</Box>
				<Typography>
					{callDetails.call_date} @ {callDetails.call_time} for {timeToMinutes(callDetails.call_duration)} min
				</Typography>
			</Box>
		</Box>
	)
}
