import { Box, Typography, Chip } from '@mui/material'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import CallMadeIcon from '@mui/icons-material/CallMade'

export default function partnerLeadDetailCallHistory(props) {
	function timeToMinutes(time) {
		let parts = time.split(':')
		let hours = parseInt(parts[0], 10)
		let minutes = parseInt(parts[1], 10)
		let seconds = parseInt(parts[2], 10)
		function truncateToTwoDecimals(number) {
			return Math.trunc(number * 100) / 100
		}

		// Convert hours and seconds to minutes
		return `${truncateToTwoDecimals(hours * 60 + minutes + seconds / 60)}`
	}
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				paddingX: 10,
			}}>
			{/* Call History Card */}
			{props.callLogs.meta_data?.data ? (
				props.callLogs.meta_data.data.map((log, index) => (
					<>
						<Box
							sx={{ padding: 2, margin: 2, borderRadius: 2, border: '1px solid #c1c1c1' }}
							key={`${index}-${log.customer_number}`}>
							<Box sx={{ marginLeft: 1, marginBottom: 2 }}>
								<Box sx={{ display: 'flex' }}>
									<Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', marginBottom: 2 }}>
										{log.call_direction === 'incoming' ? (
											<CallReceivedIcon sx={{ color: 'green', marginRight: 0.5, width: '25px' }} />
										) : (
											<CallMadeIcon sx={{ color: 'orange', marginRight: 0.5, width: '25px' }} />
										)}
										<Typography sx={{ color: '#2196F3' }}>{log.call_direction.toUpperCase()}</Typography>
									</Box>
									<Typography>
										<Chip
											label={`${log.call_status}`}
											sx={{
												color: 'white',
												backgroundColor:
													log.call_status === 'Connected'
														? 'green'
														: log.call_status === 'Not Connected'
														? 'orange'
														: 'red',
											}}
										/>
									</Typography>
								</Box>
								{/* <Typography sx={{ flexGrow: 1, color: 'orange' }} variant="h6">
						Outgoing
					</Typography> */}
								<Typography>12/07/2024 @ 13:44 for {timeToMinutes(log.call_duration)} min</Typography>
							</Box>
							<audio controls style={{ width: '100%' }}>
								<source src={`${log.recording_url}`} type="audio/ogg" />
								Your browser does not support the audio tag.
							</audio>
						</Box>
					</>
				))
			) : (
				<Typography variant="h6" align="center" marginTop={4}>
					No Logs Found
				</Typography>
			)}
		</Box>
	)
}
