/* eslint-disable no-unused-vars */
import { Box, Button, CircularProgress, Dialog } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { setWhatsapp } from '../../slices/userDetailsSlice'
import { logout } from '../../slices/userDetailsSlice'
export default function WhatsappQRModal() {
	const dispatch = useDispatch()
	const handleLogout = () => {
		// redirectHandler('/login')
		dispatch(logout())
	}
	// QR Code
	const [qrCode, setQrCode] = useState(null)
	const user = useSelector((state) => state.user)
	const getQRCode = () => {
		axios
			.get('https://api.chat.clinicspots.com/api/sales/whatsapp/qr-code', {
				headers: {
					'x-auth-token': user.token,
				},
			})
			.then((res) => {
				setQrCode(res.data.data.qrCode)
			})
	}
	const [loop, setLoop] = useState()
	useEffect(() => {
		if (user.whatsapp === false) {
			getQRCode()
			setLoop(setInterval(checkActive, 5000))
		} else {
			clearInterval(loop)
		}
	}, [user.whatsapp])

	const checkActive = () => {
		axios
			.get('https://api.chat.clinicspots.com/api/sales/whatsapp/validation', {
				params: {
					emp_id: user.userId,
				},
			})
			.then((res) => {
				if (res.data.data === true) {
					dispatch(setWhatsapp(true))
				}
			})
	}
	return (
		<Dialog open={!user.whatsapp}>
			<Box display="flex" flexDirection="column" justifyItems="center" alignItems="center" sx={{ paddingX: 8, paddingY: 2 }}>
				<h4>Scan the QR code to continue</h4>
				{qrCode ? (
					<img src={qrCode} alt="QR Code" width="200" height="200" />
				) : (
					<div
						style={{
							width: 200,
							height: 200,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<CircularProgress size={70} />
					</div>
				)}
			</Box>
			<Button
				variant="contained"
				color="error"
				disableElevation
				sx={{ position: 'fixed', bottom: '18%', left: '50%', transform: 'translate(-50%, 0%)' }}
				onClick={handleLogout}>
				Log Out
			</Button>
		</Dialog>
	)
}
